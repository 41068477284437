<template>
  <div class="footer">
    <div class="d-flex justify-content-end p-2">
        <a href="https://www.facebook.com/DerekDrappDev/"><i class="bi-facebook social-icon" style="font-size: 24px;"></i></a>
        <a href="https://www.instagram.com/derekdrapp/"><i class="bi-instagram social-icon" style="font-size: 24px;"></i></a>
        <a href="https://twitter.com/Dmdrapp"><i class="bi-twitter social-icon" style="font-size: 24px;"></i></a>
        <a href="https://github.com/Dmdrapp"><i class="bi-github social-icon" style="font-size: 24px;"></i></a>
        <a href="https://www.linkedin.com/in/derekdrapp/"><i class="bi-linkedin social-icon" style="font-size: 24px;"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
    min-height: 50px;
    background: #FFFFFF;
}
.social-icon {
  transition: ease-in-out .2s;
  cursor: pointer;
  color: #333333;
}
/* .bi-facebook {
  color: #3B5998;
}
.bi-instagram {
  color: #C13584;
}
.bi-twitter {
  color: #1BAAE1;
}
.bi-github {
  color: #333333;
}
.bi-linkedin {
  color: #4875B4;
} */
.social-icon:hover {
  text-shadow: 2px 2px 2px #a3a3a3;
}
i {
    margin-left: 8px;
}
</style>
