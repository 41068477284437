<template>
  <div class="NavigationBar">
    <div class="navbar navbar-custom fixed-top navbar-expand-sm navbar-dark bg-dark">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand">Derek Drapp</router-link>
        
        <button id="collapse" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link float-start" v-on:click="closeNavCollapse()">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/About" class="nav-link float-start" v-on:click="closeNavCollapse()">About</router-link>
            </li>
            <li class="nav-item">
              <a href="https://blog.derekdrapp.com" class="nav-link float-start">Blog</a>
            </li>
            <li class="nav-item">
              <router-link to="/Contact" class="nav-link float-start" v-on:click="closeNavCollapse()">Contact</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.navbar-custom {
  background-color: #023E8A !important;
}
.navbar-custom .navbar-brand {
  color: white;
}
.navbar-custom .nav-link {
  color: white !important;
}
.navbar-custom .navbar-toggler-icon {
  color: white;
}
</style>

<script>
// @ is an alias to /src

export default {
  name: "NavigationBar",
  methods: {
    closeNavCollapse() {
      var navbar = document.getElementById('navbarNav')
      navbar.classList.remove('show');
    }
  }
};
</script>
