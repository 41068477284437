<template>
  <div class="home">
    <div class="heading justify-content-evenly container-fluid">
      <div class="row position-relative">
        <div class="profile-text text-start col-lg order-lg-0 order-1 px-0">
          <div class="rounded-div p-4 d-flex flex-column justify-content-evenly">
            <h1 class="display-2 fw-bold name mb-5">Hello!<br />My name is <span class="colorName">Derek</span></h1>
            <p class="fs-5 container">I'm a full-stack developer who loves building and developing
              websites as well as programs for fun and work. If you want
              to take your website to the next level and build a better
              experience for your customers, I can help you get there!
            </p>
            <!-- <p>
              Whether to rebuild your webpage from scratch, or enhance what
              you already have, my experience and expertise will bring value
              and help you reach your goals.
            </p> -->
            <router-link class="mt-3 btn btn-lg btn-primary hire align-self-start ms-3" to="/Contact">Hire Me</router-link>
          </div>
        </div>
        <div class="col-lg order-lg-1 order-0 profile-div d-flex flex-column justify-content-end align-items-center">
          <img class="profile" src="@/assets/MishyaRai-177.png" alt="Derek Drapp">
        </div>
      </div>
    </div>

    <div class="experience-panel container-fluid px-0">
      <div class="experience p-4">
        <div class="mb-4">
          <h2 class="display-2 fw-bold">5+</h2>
          <h3>Years experience in...</h3>
        </div>
        <div>
          <div class="row g-2 justify-content-center mb-2">
            <div class="card col-md mx-2">
              <div class="card-body">
                <i class="bi-code-slash" style="font-size: 48px;"></i>
                <h3 class="card-title fw-bold">Web Development</h3>
                <p class="card-text">There are many build your own web programs
                  that you can build a template from, but with a custom-made website,
                  you can stand out from the crowd
                </p>
              </div>
            </div>
            <div class="card col-md mx-2">
              <div class="card-body">
                <i class="bi-diagram-3" style="font-size: 48px;"></i>
                <h3 class="card-title fw-bold">UX/UI Design</h3>
                <p class="card-text">Having a clean, professional look and experience
                  is crucial for any company or brand. We will walk through and discuss
                  what you're trying to convey through your page.
                </p>
              </div>
            </div>
            <div class="card col-md mx-2">
              <div class="card-body">
                <i class="bi-search" style="font-size: 48px;"></i>
                <h3 class="card-title fw-bold">Search Engine Optimization</h3>
                <p class="card-text">
                  Even the best designed website won't provide any value if no one 
                  can find it. That's why optimizing your site for top search engines
                  like Google, Bing, etc. is so important.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="portfolio">
      <div class="portfolio-panel container-fluid p-4 position-relative d-flex flex-column">
        <div>
          <h2 class="">Projects I've done</h2>
          <p>
            Here are just a few of the websites I've built with either custom code
            or with Wordpress for some clients.
          </p>
        </div>
        <div class="mt-3 image-group d-flex flex-row justify-content-center flex-wrap">
          <div class="d-flex flex-wrap justify-content-center">
            <img src="@/assets/DrappStudios.png" alt="Drapp Studio Page" srcset="" class="rounded image mb-3 mx-3">
            <img src="@/assets/UltimateGym.png" alt=" Ultimate Gymnastics Page" srcset="" class="rounded image mb-3 mx-3">
          </div>
          <div class="d-flex flex-wrap justify-content-center">
            <img src="@/assets/FocusProduction.png" alt="Focus Production Page" srcset="" class="rounded image mb-3 mx-3">
            <img src="@/assets/CornerStone.png" alt="Cornerstone Home Decor Page" srcset="" class="rounded image mb-3 mx-3">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};

</script>

<style>
html {
  scroll-behavior: smooth;
}
.home {
  background: #023E8A;
}
.name {
  font-family: 'rock salt', 'roboto';
}
.colorName {
  color: #023e8a;
}
.heading {
  min-height: 300px;
  background: #FFFFFF;
}
.profile-div {
  background: #023E8A;
  overflow: hidden;
  border-radius: 0 0 0 50%;
}
.profile-text {
  background: #023E8A;
}
.hire {
  background: #023E8A !important;
  border: #023E8A !important;
}
.rounded-div {
  background: #FFFFFF;
  border-radius: 0 50% 0 0;
  height: 100%;
  min-height: 200px;
}
.profile {
  width: 50%;
  max-width: 500px;
}
.experience-panel {
  min-height: 300px;
  background: #023E8A;
}
.experience {
  background: #FFFFFF;
  border-radius: 0 0 32px 32px;
}
.portfolio {
  background: #FFFFFF;
}
.portfolio-panel {
  min-height: 300px;
  background: #023E8A;
  color: #FFFFFF;
  border-radius: 0 0 32px 32px;
  box-shadow: 0px 3px 4px #333333;
}
.image {
  width: 250px;
  border: 2px solid black;
}
.row .card {
  max-width: 400px;
}

@media only screen and (min-width: 576px) {

}

@media only screen and (min-width: 768px) {
  .image {
    width: 300px;
    border: 2px solid black;
  }

}

@media only screen and (min-width: 992px) {
    .profile {
    width: 70%;
  }
}

@media only screen and (min-width: 1400px) {
  .image {
    width: 400px;
    border: 2px solid black;
  }
}

@media only screen and (min-width: 1400px) {
}
</style>